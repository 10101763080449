import React, { Component, PureComponent } from "react";
import { Route, Router } from "react-router-dom";
import history from "./history";
import { CallBack, AuthenticationWrapper } from "../auth";
import FetchCustomersWrapper from "../customers/CustomersComponent";
import axios from "axios";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { Loader } from '../components'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#2196f3",
      main: "#1769aa",
      dark: "#4dabf5",
      contrastText: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
});
export const ConfigContext = React.createContext({});

class MainRoute extends PureComponent {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ConfigContext.Consumer>
          {(value) => (
        <AuthenticationWrapper config={{...value}}>
          <FetchCustomersWrapper config={{...value}} />
        </AuthenticationWrapper>)}
        </ConfigContext.Consumer>
      </MuiThemeProvider>
    );
  }
}

class App extends Component {
  state = {
    configIsLoaded: false
  };

  componentDidMount() {
    this.loadConfig();
  }

  async loadConfig() {
    try {
      const { data } = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
      const { data: { auth_client_id } } = await axios.get(`${data.REACT_APP_API_URL}/customers/id`);

      this.setState(prevState => ({
        ...prevState,
        configIsLoaded: true,
        config: {
          ...data,
          REACT_APP_AUTH_CLIENT_ID: auth_client_id,
          REACT_APP_AUTH_CONNECTION: `conn-${auth_client_id}`
        }
      }));
    } catch (e) {
      console.log("Config.json could not be loaded", e);
    }
  }

  render() {
    if (!this.state.configIsLoaded) {
      return <Loader />;
    }
    return (
        <Router history={history}>
          <div>
            <ConfigContext.Provider value={this.state.config}>
              <Route path="/" exact render={props => <MainRoute {...props} />} />
            </ConfigContext.Provider>
              <Route path="/auth/callback" render={(props) => <CallBack  config={{...this.state.config}} {...props}/>} />
          </div>
        </Router>
    );
  }
}

export default App;
