import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { LocationView } from "../locations-view";

import { AbstractTable, Loader } from "../components";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LocationOn from "@material-ui/icons/LocationOn";
import ActionPanel from "../action-pannel/ActionPannel";
import Snackbar from "@material-ui/core/Snackbar";
import { setTagsToState, setTagToState } from "./tags-api";
import { filterCollection } from "../utils";

class TagsView extends PureComponent {
  static propTypes = {
    setLocationCallback: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      is_dialog_open: false,
      hasError: false,
      data: [],
      pending: true,
      search_value: "",
      snackbar_is_open: false,
      show_only_active_tags: true
    };
    this.setTagsToState = setTagsToState.bind(this);
    this.setTagToState = setTagToState.bind(this);
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  componentDidMount() {
    this.setTagsToState(this.props.currentCustomer.id);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentCustomer.id !== this.props.currentCustomer.id) {
      this.setTagsToState(this.props.currentCustomer.id);
    }
  }

  changeActiveTagsHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      show_only_active_tags: !prevState.show_only_active_tags
    }));
  };

  handleClickOpen = tag => {
    this.setState(prevState => ({
      ...prevState,
      is_dialog_open: true,
      current_tag: tag
    }));
  };

  handleClose = () => {
    this.setState(prevState => ({
      ...prevState,
      is_dialog_open: false,
      current_tag: null
    }));
  };

  changeSearchValue = value => {
    this.setState(prevState => ({ ...prevState, search_value: value }));
  };

  handleSnackbarOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      snackbar_is_open: true
    }));
  };
  handleSnackbarClose = () => {
    this.setState(prevState => ({
      ...prevState,
      snackbar_is_open: false
    }));
  };

  columns = [
    {
      label: "Tag ID",
      property: "tag_value",
      id: "tag_value"
    },
    {
      label: "Tag Label",
      property: "label",
      id: "label"
    },
    {
      label: "Display Name",
      property: "current_patient_department_visit_secured_name",
      id: "current_patient_department_visit_secured_name"
    },
    {
      label: "Current Location",
      render: ({ current_location_alias_name, current_location_name }) =>
        current_location_alias_name || current_location_name,
      id: "current_location_name"
    },
    {
      label: "Current Sensor",
      property: "current_sensor_value",
      id: "current_sensor_value"
    },
    {
      label: "Location Updated",
      property: "sensed_utc",
      render: ({ sensed_utc }) =>
        !sensed_utc && this.props.currentCustomer.default_timezone
          ? "-"
          : moment(sensed_utc)
              .tz(this.props.currentCustomer.default_timezone)
              .format("YYYY-MM-DD HH:mm:ss"),
      id: "sensed_utc"
    },
    {
      label: "Action",
      isNotSortable: true,
      id: "action",
      render: item => {
        return (
          <IconButton
            onClick={() => {
              this.handleClickOpen(item);
            }}
            color="primary"
            className={this.props.classes.button}
            aria-label="Add an alarm"
          >
            <LocationOn />
          </IconButton>
        );
      }
    }
  ];

  render() {
    const {
      snackbar_is_open,
      data,
      search_value,
      pending,
      hasError,
      is_dialog_open,
      current_tag,
      show_only_active_tags
    } = this.state;
    const keys = [
      "tag_value",
      "label",
      "current_location_alias_name",
      "current_location_name"
    ];
    if (pending) {
      return <Loader />;
    }
    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const filteredData = show_only_active_tags
      ? data.filter(
          ({ current_patient_department_visit_department_id }) =>
            current_patient_department_visit_department_id
        )
      : data;
    const searched_data = filterCollection(filteredData, keys, search_value);
    return (
      <React.Fragment>
        <ActionPanel
          checkboxValue={"show_only_active_tags"}
          checked={this.state.show_only_active_tags}
          changeActiveTagsHandler={this.changeActiveTagsHandler}
          changeSearchValue={this.changeSearchValue}
          refresh={this.setTagsToState}
          currentCustomer={this.props.currentCustomer}
          config={{...this.props.config}}
          auth={{...this.props.auth}}
        />
      {is_dialog_open&&<LocationView
          pending={this.state.pending}
          open={is_dialog_open}
          handleClickOpen={this.handleClickOpen}
          handleClose={this.handleClose}
          current_tag={current_tag}
          setTagToState={this.setTagToState}
          handleSnackbarOpen={this.handleSnackbarOpen}
          handleSnackbarClose={this.handleSnackbarClose}
          currentCustomer={this.props.currentCustomer}
          config={{...this.props.config}}
          auth={{...this.props.auth}}
        />}

        <AbstractTable
          rowsPerPage={25}
          columns={this.columns}
          data={searched_data}
          handleClickOpen={this.handleClickOpen}
        />
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbar_is_open}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Tag location has been updated</span>}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    textAlign: "center"
  },
  table: {
    minWidth: 700,
    maxHeight: 300
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

export default withStyles(styles)(TagsView);
