import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControlLabel,
  TextField,
  InputAdornment,
  Checkbox
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SyncIcon from "@material-ui/icons/Sync";
import SearchIcon from "@material-ui/icons/Search";
import ResetTags from "../tags-view/ResetTags";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    alignSelf: "flex-end"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing.unit
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  spacer: { width: 30 },
});

class ActionPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    checkboxValue: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired
  };
  render() {
    const {
      classes,
      changeSearchValue,
      checked,
      changeActiveTagsHandler,
      checkboxValue,
      refresh,
      currentCustomer
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <TextField
            className={classes.margin}
            id="input-with-icon-textfield"
            onChange={e => changeSearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={changeActiveTagsHandler}
                value={checkboxValue}
                color="primary"
              />
            }
            label="Show only active tags"
          />
        </div>

          <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => refresh(currentCustomer.id)}
          >
            <SyncIcon className={classes.leftIcon} />
            Refresh
          </Button>
          <div className={classes.spacer} />
          <ResetTags refresh={refresh} currentCustomer={currentCustomer} config={{...this.props.config}} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ActionPanel);
