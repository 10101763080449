import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { setCustomersToState } from "./customers-api";
import Loader from "../components/Loader";
import MainAppBar from "../main-bar/MainBar";
import TagsView from "../tags-view/TagsView";

export default class FetchCustomersWrapper extends PureComponent {
  static propTypes={
    auth:PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.setCustomersToState = setCustomersToState.bind(this);
    this.state = {
      customers: [],
      pending: true,
      currentCustomer: null
    };
  }
  componentDidMount() {
    this.setCustomersToState();
  }
  changeCustomer = e => {
    const newCustomer = this.state.customers.find(
      item => (item.id === e.target.value)
    );
    const currentCustomer = this.state.currentCustomer;
    window.location.href = window.location.href.replace(currentCustomer.subdomain, newCustomer.subdomain);
  };
  render() {
    const {currentCustomer,customers}=this.state;
    if (this.state.pending) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <MainAppBar
          auth={this.props.auth}
          customers={customers}
          currentCustomer={currentCustomer}
          changeCustomer={this.changeCustomer}
        />
        <TagsView
          currentCustomer={currentCustomer}
          config={{...this.props.config}}
          auth={{...this.props.auth}}
        />
      </React.Fragment>
    );
  }
}
