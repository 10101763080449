import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { resetTags } from "./tags-api";

class AlertDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    };
    this.resetTags=resetTags.bind(this);
  }


  handleClickOpen =()=> {
    this.setState(prevState => ({ ...prevState, open: true }));
  };

  handleClose =()=> {
    this.setState(prevState => ({ ...prevState, open: false }));
  };

  submit = async () => {
    try {
      await this.resetTags(this.props.currentCustomer.id);
      this.handleClose();
      this.props.refresh(this.props.currentCustomer.id);
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Reset time
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Reset current locations and phases time data?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will set current location and phase updated time data
              close to now within the range of 15 minutes. Are you sure want to
              continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={this.submit} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
