import axios from "axios";

export async function resetTags(customerId) {
  try {
    const Authorization = sessionStorage.getItem("access_token");
    return await axios.post(
      `${this.props.config.REACT_APP_API_URL}/tags/reset-data`,
      {},
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        },
        params: {
          range: 900
        }
      }
    );
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e;
  }
}

export async function setTagsToState(customerId) {
  try {
    this.setState(prevState => ({ ...prevState, pending: true }));
    const Authorization = sessionStorage.getItem("access_token");
    const response = await axios.get(
      `${this.props.config.REACT_APP_API_URL}/tags/`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        }
      }
    );
    if (response) {
      this.setState(prevState => ({ ...prevState, data: response.data }));
    }
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e;
  } finally {
    this.setState(prevState => ({ ...prevState, pending: false }));
  }
}

export async function setTagToState(tag) {
  try {
    const Authorization = sessionStorage.getItem("access_token");
    const response = await axios.get(
      `${this.props.config.REACT_APP_API_URL}/tags`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Authorization}`
        },
        params: {
          id: tag.id
        }
      }
    );
    this.setState(prevState => {
      let clone = prevState.data.map(item => {
        if (item.id === tag.id) {
          return { ...response.data[0] };
        }
        return { ...item };
      });
      return {
        ...prevState,
        data: clone
      };
    });
  } catch (e) {
    console.log(e);
    if (e.response) {
      return e.response.status === 401 && this.props.auth.logout();
    }
    throw e;
  } finally {
    this.setState(prevState => ({ ...prevState, pending: false }));
  }
}
